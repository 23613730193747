@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  overflow: hidden;
  font-family: 'Inter', sans-serif, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: #7f56d9;
    --primary-foreground: rgb(243, 237, 255);

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    /* --ring: 215 20.2% 65.1%; */

    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    /* --ring: 216 34% 17%; */

    --radius: 0.5rem;
  }
}

@layer base {
  body {
    @apply bg-background text-foreground;
    font-feature-settings:
      'rlig' 1,
      'calt' 1;
  }
}

#root {
  min-height: 100vh;
  height: 100%;
}

/* .change-imp
  .ant-form-item-label
  label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-inline-end: 4px;
  color: #1f192b;
  font-size: 14px;
  line-height: 1;
  content: '*';
  visibility: visible;
} */

.signature-color-picker.type-public .ant-color-picker-color-block {
  height: 32px;
  width: 32px;
}

.signature-color-picker.controls .ant-color-picker-color-block {
  height: 32px;
  width: 32px;
  border-radius: 4px;
}

.auth-modal .ant-modal-content {
  padding: 32px;
  border-radius: 8px;
  background-color: #f3edff;
}

/* Styles for headshot */
.headshot {
  overflow: hidden;
  display: flex;
}

/* Styles for headshot wrapper */
.headshot .headshot-wrapper {
  width: inherit;
  height: inherit;
}

/* Styles for image wrapper */
.headshot .headshot-wrapper .image-wrapper {
  height: 100%;
  position: relative;
}

/* Styles for ant image */
.headshot .headshot-wrapper .image-wrapper .ant-image {
  position: absolute;
  inset: 0;
  height: inherit;
  width: inherit;
}

/* Styles for image inside ant image */
.headshot .headshot-wrapper .image-wrapper .ant-image img {
  height: 100%;
  width: 100%;
}

.image-upload-with-crop.hide .ant-upload-drag {
  display: none;
}

.image-upload-with-crop .ant-upload-list {
  display: flex;
  justify-content: center;
}

.image-upload-with-crop
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item::before {
  height: 100% !important;
  width: 100% !important;
}

.image-upload-with-crop .ant-upload-list-item-container {
  margin: 0;
}

.image-upload-with-crop .ant-upload-list-item-container .ant-upload-list-item {
  padding: 0;
  height: 111px;
  margin-bottom: 32px;
  border: none;
}

.image-upload-with-crop
  .ant-upload-list-item-container
  .ant-upload-list-item.ant-upload-list-item-error {
  border: none;
}

.antd-input-only-bottom-border-style {
  border: none;
  border-bottom: 1px solid var(--primary);
  border-radius: 0px;
  background-color: transparent;
  padding: 0;
  margin: 0;
}

/* Scrollbar Styles */

::-webkit-scrollbar,
::-webkit-scrollbar-thumb {
  width: 5px;
  background-clip: padding-box;
  color: transparent;
  border-left: 0 solid transparent;
  border-radius: 5px;
}

/* Scrollbar Thumb Styles */
::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 0 8px;
  box-shadow: inset 0 0 0 8px transparent;
}

/* Scrollbar Thumb Hover Styles */
:hover::-webkit-scrollbar-thumb {
  border-left: 5px solid var(--primary);
  background-clip: padding-box;
  -webkit-box-shadow: inset 0 0 0 8px;
  box-shadow: inset 0 0 0 8px transparent;
}

.headshot-border {
  border: 1px solid;
  color: #958da5;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Custom scrollbar styles */
.custom-scrollbar::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the track */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888; /* Color of the thumb */
  border-radius: 10px; /* Round the thumb edges */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the thumb when hovered */
}

/* For IE, Edge and Firefox */
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1; /* thumb and track colors */
}

.active-headshot,
.headshot-border:hover {
  border: 1px solid var(--primary); /* Use CSS variable for the border color */
  color: var(--primary); /* Use CSS variable for the color */
}

table a {
  text-decoration: none !important;
}

.ipl-progress-indicator.available {
  opacity: 0;
}

.loading-logo {
  height: 64px;
}

.ipl-progress-indicator {
  background-color: #f5f5f5;
  width: 100%;
  height: 100%;
  position: fixed;
  opacity: 1;
  pointer-events: none;
  -webkit-transition: opacity cubic-bezier(0.4, 0, 0.2, 1) 436ms;
  -moz-transition: opacity cubic-bezier(0.4, 0, 0.2, 1) 436ms;
  transition: opacity cubic-bezier(0.4, 0, 0.2, 1) 436ms;
  z-index: 9999;
}

.insp-logo-frame {
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  align-items: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-animation: fadein 436ms;
  -moz-animation: fadein 436ms;
  animation: fadein 436ms;
  height: 98%;
}

.insp-logo-frame-img {
  width: 112px;
  height: 112px;
  -webkit-align-self: center;
  -moz-align-self: center;
  align-self: center;
  border-radius: 50%;
}

.ipl-progress-indicator-head {
  background-color: #c6dafc;
  height: 4px;
  overflow: hidden;
  position: relative;
}

.ipl-progress-indicator .first-indicator,
.ipl-progress-indicator .second-indicator {
  background-color: #7f56d9;
  /* define progress color here */
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  position: absolute;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  transform-origin: left center;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  transform: scaleX(0);
}

.ipl-progress-indicator .first-indicator {
  -webkit-animation: first-indicator 2s linear infinite;
  -moz-animation: first-indicator 2s linear infinite;
  animation: first-indicator 2s linear infinite;
}

.ipl-progress-indicator .second-indicator {
  -webkit-animation: second-indicator 2s linear infinite;
  -moz-animation: second-indicator 2s linear infinite;
  animation: second-indicator 2s linear infinite;
}

.ipl-progress-indicator .insp-logo {
  animation: App-logo-spin infinite 20s linear;
  border-radius: 50%;
  -webkit-align-self: center;
  -moz-align-self: center;
  align-self: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-moz-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes first-indicator {
  0% {
    transform: translate(0) scaleX(0);
  }

  25% {
    transform: translate(0) scaleX(0.5);
  }

  50% {
    transform: translate(25%) scaleX(0.75);
  }

  75% {
    transform: translate(100%) scaleX(0);
  }

  100% {
    transform: translate(100%) scaleX(0);
  }
}

@keyframes second-indicator {
  0% {
    transform: translate(0) scaleX(0);
  }

  60% {
    transform: translate(0) scaleX(0);
  }

  80% {
    transform: translate(0) scaleX(0.6);
  }

  100% {
    transform: translate(100%) scaleX(0.1);
  }
}


@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.custom-pulse {
  animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}